@import 'styles/variables.less';

.cardCompany {
  margin-bottom: 0 !important;
  background-color: @shopping-cart-background !important;
  .title {
    > h3 {
      &::first-letter {
        text-transform: uppercase;
      }
      word-break: break-all;
    }
    > div {
      width: 100%;
      word-break: break-all;
      white-space: normal;
      color: rgb(79, 79, 79);
      font-style: italic;
    }
  }
  > :last-child {
    display: flex;
    justify-content: space-between;
    .companyCartContent {
      min-width: 170px;
      display: flex;
      flex-direction: column;
      flex: 1;

      margin-right: 16px;
    }
  }
  .cardCompanyActions {
    align-self: flex-end;
  }
}

.createForm {
  label {
    white-space: nowrap;
  }
}
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: @gray-6;
}

.confirmModal {
  z-index: 1001;
}

.confirmModal > div > div > div > :last-child {
  display: none;
}

.abbreviation {
  color: #8c8c8c;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);