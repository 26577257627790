@import 'styles/variables.less';

.showPeriodSwitchContainer {
  height: 34px;
}

.periodFieldsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.endPeriodContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.optionalText {
  color: @gray-7;
  font-style: italic;
}

.fieldsContainer {
  margin-left: 50px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);