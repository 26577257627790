.client_details {
  font-size: 15px;
  div {
    flex-wrap: nowrap !important;
    @media (max-width: 1200px) {
      flex-direction: column !important;
    }
  }
}

.client_details_label {
  font-weight: 600;
  min-width: 224px;
}

.client_details_value {
  overflow-wrap: anywhere;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);