.enableDigitalSignatureSwitch {
  margin-bottom: 24px;
}
.indentSwitch {
  margin-top: 24px;
  margin-left: 50px;
}

.emailAddressesAlert {
  margin-top: 24px;
}

.sepaCustomSettings {
  margin-top: 16px !important;
}

.sepaMandateInfo {
  display: flex;
  width: fit-content;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  border-radius: 5px;
  padding: 5px 16px;
  display: flex;
  margin: 16px 0;
  > :first-child {
    margin-top: 3px;
    height: max-content;
    display: flex;
    svg {
      fill: var(--primary-color);
    }
  }
  > :last-child {
    margin-left: 8px;
    flex: 1;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);